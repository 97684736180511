import { axiosAuthorized } from "../../api/config"

export const getUsers = async (userId) => {
  try {
    const response = await axiosAuthorized.get(`/getusercompletedregisterdata/${userId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getSavedUsers = async (userId) => {
  try {
    const response = await axiosAuthorized.get(`/getusersaveddata/${userId}`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addUser = async (initialState, user_saved, free_card, free_tests, free_packages, id = null) => {
  const totalTestPrice = free_tests?.reduce((acc, curr) => acc + curr?.total_price, 0) || 0;
  const totalPackagePrice = free_packages?.reduce((acc, curr) => acc + curr?.health_package_mrp, 0) || 0;
  free_tests = free_tests?.map(test => test?._id);
  free_packages = free_packages?.map(packageData => packageData?._id);
  const total_price = totalTestPrice + totalPackagePrice;
  try {
    const data = {
      ...initialState,
      phone_number: Number(initialState.phone_number),
    };
    const requestData = {
      ...data,
      free_card,
      free_tests,
      total_price,
      free_packages,
      user_saved: false
    } 
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = id
      ? `/salesbduser/update_salesbduser/${id}`
      : `/salesbduser/add_bdregister`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteUser = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/salesbduser/delete_salesbduser/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}