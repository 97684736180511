import React, { useCallback, useEffect, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { useForm } from "../../hooks";
import {
  NumberInput,
  SelectInput,
  TextInput,
} from "../Common/FormComponents";
import { toast } from "react-toastify";
import { addUser } from "../../utils/userUtils/userUtils";
import { getTests } from '../../utils/testsUtils/testsUtils';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { IoSearchOutline } from "react-icons/io5";
import { getPackages } from "../../utils/packagesUtils/packagesUtils";

const emailPattern = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;
const phoneNumberPattern = /^(?!0{10}$)\d{10}$/;

const MyUsersModal = ({
  setOpen,
  editData = null,
  setEditData = () => { },
  setDatasFn = () => { },
  user,
}) => {
  const initialState = {
    bd_id: user?._id,
    name: editData?.name || "",
    phone_number: editData?.phone_number || "",
    email_id: editData?.email_id || "",
    region: editData?.region || "",
    bp: editData?.bp || "",
    sugar: editData?.sugar || "",
    height: editData?.height || "",
    weight: editData?.weight || "",
    spo2: editData?.spo2 || "",
    blood: editData?.blood || "",
    age: editData?.age || "",
    sex: editData?.sex || "",
    adress: editData?.adress || "",
    area: editData?.area || "",
    city: editData?.city || "",
    pincode: editData?.pincode || "",
    diabetic: editData?.diabetic || "",
    hypertenion: editData?.hypertenion || "",
    any_other: editData?.any_other || "",
    grbs: editData?.grbs || "",
    hp: editData?.hp || "",
    bd_name: editData?.bd_name || "",
    location: editData?.location || "",
  };
  const [free_card, setFreeCard] = useState(editData?.free_card || false);
  const [loading, setLoading] = useState(false);
  const [formData, handleFormData, clearForm] = useForm(initialState);
  const [err, setErr] = useState({ global: "" });

  const [tests, setTests] = useState([]);
  const [selectedTest, setSelectedTest] = useState(null);
  const setFreeTestsFn = useCallback(() => {
    getTests()
      .then((res) => setTests(res.data.data))
      .catch((error) => console.error(error));
  }, []);
  useEffect(() => {
    setFreeTestsFn();
  }, [setFreeTestsFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = tests?.filter((test) => {
      const nameMatch = test?.test_name
        ?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      const idMatch = test?.test_id
        ?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      return nameMatch || idMatch;
    });
    setFilteredData(filteredData);
  }, [tests, searchQuery]);
  const [free_tests, setFreeTestData] = useState(
    editData?.free_tests || []
  );
  const handleFreeTestData = (action, idx = 0, item = null) => {
    if (action === "add") {
      if (!item) return;
      const exist = free_tests.find(
        (data) => data?._id === item?._id
      );
      if (exist) return;
      setSelectedTest(item);
      setFreeTestData([...free_tests, item]);
    } else if (action === "remove") {
      setFreeTestData(free_tests.filter((_, index) => index !== idx));
    }
  };

  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const setFreePackagesFn = useCallback(() => {
    getPackages()
      .then((res) => setPackages(res.data.data))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    setFreePackagesFn();
  }, [setFreePackagesFn]);
  const [packageSearchQuery, setPackageSearchQuery] = useState("");
  const [filteredPackageData, setFilteredPackageData] = useState([]);
  useEffect(() => {
    const filteredPackageData = packages?.filter((test) => {
      const nameMatch = test?.health_package_name
        ?.toLowerCase()
        ?.includes(packageSearchQuery.toLowerCase());
      return nameMatch;
    });
    setFilteredPackageData(filteredPackageData);
  }, [packages, packageSearchQuery]);
  const [free_packages, setFreePackageData] = useState(
    editData?.free_packages || []
  );

  const handleFreePackageData = (action, idx = 0, item = null) => {
    if (action === "add") {
      if (!item) return;
      const exist = free_packages.find(
        (data) => data?._id === item?._id
      );
      if (exist) return;
      setSelectedPackage(item);
      setFreePackageData([...free_packages, item]);
    } else if (action === "remove") {
      setFreePackageData(free_packages.filter((_, index) => index !== idx));
    }
  };

  const handleSubmit = (user_saved = null) => {
    if (!formData.name.trim().length) return setErr({ global: "Invalid Name" });
    if (!emailPattern.test(formData.email_id))
      return setErr({ global: "Invalid Email" });
    if (!phoneNumberPattern.test(formData.phone_number))
      return setErr({ global: "Invalid Phone" });
    setErr({ global: "" });
    if (!editData && user_saved === true) {
      const confirmed = window.confirm("Are you sure you want to save ?");
      if (!confirmed) return;
    }
    if (!editData && !user_saved) {
      const confirmed = window.confirm("Are you sure you want to register ?");
      if (!confirmed) return;
    }
    setLoading(true);
    addUser(formData, user_saved, free_card, free_tests, free_packages, editData?._id)
      .then((res) => {
        setDatasFn();
        clearForm();
        setEditData(null);
        setOpen(false);
        if (user_saved === true) {
          toast(`User Saved`, {
            type: "success",
            autoClose: 3000,
          });
        } else {
          toast(`User ${editData ? "Edited" : "Added"}`, {
            type: "success",
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          const errorMessage = err.response?.data || err.message;
          if (
            errorMessage.includes("E11000 duplicate key error collection")
          ) {
            toast(`User with this email or phone already exists`, {
              type: "error",
              autoClose: 3000,
            });
          } else {
            if (user_saved === true) {
              toast(`User Saving Failed`, {
                type: "error",
                autoClose: 3000,
              });
            } else {
              toast(`User ${editData ? "Edit Failed" : "Add Failed"}`, {
                type: "error",
                autoClose: 3000,
              });
            }
          }
        } else {
          toast(`An error occurred. Please try again.`, {
            type: "error",
            autoClose: 3000,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className="flex  w-full max-w-screen-md overflow-y-auto max-h-screen  border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"Register User"}</span>
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </button>
        </div>
        <div className="flex w-full h-fit justify-between gap-5 flex-col md:flex-row">
          <TextInput
            formName={"name"}
            name={"Name"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            formName={"email_id"}
            name={"Email"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5 flex-col md:flex-row">
          <TextInput
            formName={"phone_number"}
            name={"Phone Number"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <SelectInput
            formName={"sex"}
            name={"Sex"}
            items={["Select", "Male", "Female"]}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <NumberInput
            hide={true}
            formName={"age"}
            name={"Age"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5 flex-col md:flex-row">
          <SelectInput
            formName={"location"}
            name={"Location"}
            items={[
              "Select",
              "Bangalore Central",
              "Bangalore South",
              "Bangalore West",
              "Bangalore East",
              "Bangalore North",
            ]}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <SelectInput
            formName={"blood"}
            name={"Blood"}
            items={["Select", "A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"]}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5 flex-col md:flex-row">
          <TextInput
            hide={true}
            formName={"height"}
            name={"Height"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            hide={true}
            formName={"weight"}
            name={"Weight"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5 flex-col md:flex-row">
          <TextInput
            hide={true}
            formName={"bp"}
            name={"BP"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            hide={true}
            formName={"grbs"}
            name={"GRBS"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        {/* <div className="flex w-full h-fit justify-between gap-5 flex-col md:flex-row">
          <TextInput
            hide={true}
            formName={"location"}
            name={"Location"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div> */}
        {free_tests?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {free_tests?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <div className="flex items-center gap-1 text-white">
                  <div className={`flex w-7 h-7`}>
                    <img
                      src={
                        item?.test_pic instanceof File
                          ? URL.createObjectURL(item?.test_pic)
                          : item?.test_pic
                      }
                      alt={"service_image"}
                      className={`rounded-full ${item?.test_pic ? "flex" : "hidden"
                        }`}
                    />
                  </div>
                  <span>{item?.test_name}</span>
                </div>
                <button
                  onClick={() => {
                    handleFreeTestData("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="relative flex flex-col gap-0.5">
          <span className="absolute inset-y-0 left-0 top-5 flex items-center pl-2">
            <IoSearchOutline fontSize={23} />
          </span>
          <label
            htmlFor={"List Of Tests"}
            className="text-[14px] text-shadow-black text-white"
          >
            {"List Of Tests For Free Tests"}
          </label>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            className="appearance-none rounded-md w-full bg-white border border-gray-300 py-2 pl-10 pr-4 text-gray-700 leading-tight focus:outline-none focus:border-primary"
            placeholder="Search By Name, ID"
          />
        </div>
        <div className="flex gap-5 flex-wrap min-h-[150px] max-h-[200px] overflow-y-scroll px-5 m-3 ">
          {filteredData?.length > 0 ? (
            filteredData
              ?.map((item) => (
                <div
                  key={item._id}
                  onClick={() => {
                    handleFreeTestData("add", null, item);
                  }}
                  className={`flex px-2 py-1 cursor-pointer w-fit h-fit  border rounded-md ${selectedTest?._id === item?._id
                    ? `bg-white text-primary border-primary`
                    : `text-white`
                    }`}
                >
                  {`${item?.test_name} - ${item?.test_id}`}
                </div>
              ))
          ) : (
            <div className="flex h-full w-full justify-center items-center text-white">
              No Tests Found!
            </div>
          )}
        </div>
        {free_packages?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {free_packages?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <div className="flex items-center gap-1 text-white">
                  <div className={`flex w-7 h-7`}>
                    <img
                      src={
                        item?.package_image instanceof File
                          ? URL.createObjectURL(item?.package_image)
                          : item?.package_image
                      }
                      alt={"package_image"}
                      className={`rounded-full ${item?.package_image ? "flex" : "hidden"
                        }`}
                    />
                  </div>
                  <span>{item?.health_package_name}</span>
                </div>
                <button
                  onClick={() => {
                    handleFreePackageData("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="relative flex flex-col gap-0.5">
          <span className="absolute inset-y-0 left-0 top-5 flex items-center pl-2">
            <IoSearchOutline fontSize={23} />
          </span>
          <label
            htmlFor={"List Of Packages"}
            className="text-[14px] text-shadow-black text-white"
          >
            {"List Of Packages For Free Packages"}
          </label>
          <input
            type="text"
            value={packageSearchQuery}
            onChange={(e) => {
              setPackageSearchQuery(e.target.value);
            }}
            className="appearance-none rounded-md w-full bg-white border border-gray-300 py-2 pl-10 pr-4 text-gray-700 leading-tight focus:outline-none focus:border-primary"
            placeholder="Search By Name"
          />
        </div>
        <div className="flex gap-5 flex-wrap min-h-[150px] max-h-[200px] overflow-y-scroll px-5 m-3 ">
          {filteredPackageData?.length > 0 ? (
            filteredPackageData
              ?.map((item) => (
                <div
                  key={item._id}
                  onClick={() => {
                    handleFreePackageData("add", null, item);
                  }}
                  className={`flex px-2 py-1 cursor-pointer w-fit h-fit  border rounded-md ${selectedPackage?._id === item?._id
                    ? `bg-white text-primary border-primary`
                    : `text-white`
                    }`}
                >
                  {`${item?.health_package_name}`}
                </div>
              ))
          ) : (
            <div className="flex h-full w-full justify-center items-center text-white">
              No Packages Found!
            </div>
          )}
        </div>
        <div className="flex w-full gap-2">
          <span className="text-shadow-black text-white font-semibold">Give Free Card:</span>
          <input
            disabled={editData?.free_card === true}
            type="checkbox"
            checked={free_card}
            onChange={(e) => setFreeCard(e.target.checked)}
          />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={() => {
                handleSubmit(false);
              }}
            >
              {loading ? "Please Wait..." : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyUsersModal;
