import { axiosAuthorized } from "../../api/config";

export const getTestsCount = async () => {
  try {
    const response = await axiosAuthorized.get(`/counttotallabtest`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getTests = async (labId) => {
  try {
    const response = await axiosAuthorized.get(`/labtest/get_labtest`);
    return response;
  } catch (error) {
    throw error;
  }
}
export const getTestsByGeneralCategory = async (categoryId) => {
  try {
    const response = await axiosAuthorized.get(`/labtestbycategory/${categoryId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getTestsByVitalOrganCategory = async (categoryId) => {
  try {
    const response = await axiosAuthorized.get(`/labtestbyhealthpackagecategory/${categoryId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addTest = async (initialState, test, image, category, tubeId, vital_organ_category, frequently_booked_test, faq, labId, testId = null) => {
  try {
    category = category?.map(item => item?._id);
    vital_organ_category = vital_organ_category?.map(item => item?._id);
    frequently_booked_test = frequently_booked_test?.map(item => item?._id);
    const calculatedPrice = testId ? Math.floor(initialState?.price - (initialState.price * (initialState?.discount / 100))) : initialState.price;
    const requestData = {
      ...initialState,
      test,
      price: calculatedPrice,
      discount: initialState.discount,
      lab_member_id: labId,
      category,
      prescription_required: initialState.prescription_required === "required" ? true : false,
      vital_organ_category,
      frequently_booked_test,
      test_pic: image,
      tubes: tubeId,
      faq,
    };


    const requestConfig = {
      headers: { "Content-Type": image instanceof File ? "multipart/form-data" : "application/json" },
    };

    const url = testId
      ? `/lab/update_lab/${testId}`
      : `/labtest/add_labtest`;

    const response = await axiosAuthorized[testId ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteTest = async (testId) => {
  try {
    const response = await axiosAuthorized.delete(`/labtest/delete_labtest/${testId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getFrequentlyBookedTests = async () => {
  try {
    const response = await axiosAuthorized.get(`/frequentlybookedlabtest/get_frequentlybookedlabtest`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getTopBookedTests = async () => {
  try {
    const response = await axiosAuthorized.get(`/topbookedlabtest/get_topbookedlabtest`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addFrequentlyBookedTest = async (initialState, frequently_booked_test, id = null) => {
  try {
    frequently_booked_test = frequently_booked_test?.map(test => test?._id);
    const requestData = {
      ...initialState,
      frequently_booked_test
    }
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = id
      ? `/frequentlybookedlabtest/update_frequentlybookedlabtest/${id}`
      : `/frequentlybookedlabtest/add_frequentlybookedlabtest`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}
export const addTopBookedTest = async (initialState, top_booked_test, id = null) => {
  try {
    top_booked_test = top_booked_test?.map(test => test?._id);
    const requestData = {
      ...initialState,
      top_booked_test
    }
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };
    const url = id
      ? `/topbookedlabtest/update_topbookedlabtest/${id}`
      : `/topbookedlabtest/add_topbookedlabtest`;
    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}